import React from 'react'


function Page(props) {
  
  if(typeof window !== 'undefined') {
    window.location.replace('https://1st.com/1st-saturday')
  }

  return (
    <div>
      .
    </div>
  )
}

export default Page